import './App.css';

function App() {
  return (
    <main className="flex flex-col items-center justify-center w-full h-screen max-h-screen gap-2 mx-auto">
      <img
        src={"/rbsplit-logo.png"}
        width={80}
        height={80}
        alt="RBSplit Logo"
      />
      <div className="flex flex-col gap-4 mx-auto mt-8">
      <h1 className="text-3xl text-center text-neutral-900">
        <span className="font-bold text-neutral-800">RB Split</span> está en mantenimiento
      </h1>
      <p className="max-w-2xl px-4 text-xl text-center text-gray-700">
        Los administradores de nuestra empresa están trabajando en la
        construcción del sitio web
      </p>
      </div>
      <div className="flex flex-col items-center text-lg font-medium text-gray-700">
        <p>Contáctanos a través de:</p>
        <div className="flex justify-between mx-auto">
          <p className="flex px-4 py-2 text-lg font-medium text-blue-600 transition-colors">
            <span className="flex items-center mr-2">
              {/* <PhoneIcon className="w-6 h-6" /> */}
            </span>
            917 708 946
          </p>
          <a
            href="mailto:info@rbsplit.com"
            className="flex px-4 py-2 text-lg font-medium text-blue-600 transition-colors hover:underline"
          >
            <span className="flex items-center mr-2">
              {/* <EnvelopeIcon className="w-6 h-6" /> */}
            </span>
            info@rbsplit.com
          </a>
        </div>
      </div>
    </main>
  );
}

export default App;
